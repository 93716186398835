import React from 'react'
import introbg from '../assets/images/introbg.jpg'
// import PageTransition from 'gatsby-plugin-page-transitions';

const bg = {
    backgroundImage: `linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5) ), url(${introbg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
}

const Intro = () => (
<div>
    <section className="hero is-fullheight-with-navbar" style={bg}>
        <div className="hero-body">
            <div className="container has-text-centered">
                <h1 className="title is-size-1 is-uppercase">
                    Bringing you the world on a silver platter
                </h1>
            </div>
        </div>
    </section>
</div>
)

export default Intro