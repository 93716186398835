import React from 'react'

import mission from '../assets/images/mission.jpg'

const bg = {
    backgroundImage: `url(${mission})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
}

const Mission = () => (
    <div>
        <div className="columns is-gapless">
            <div className="column is-half is-paddingless">
                <div className="hero is-fullheight" style={bg}></div>
            </div>
            <div className="column is-half">
            <div className="hero is-fullheight is-warning is-paddingless">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-half">
                                <div className="content">
                                    <h1 className="title is-size-1 is-uppercase has-text-centered">Our Mission</h1>

                                    <p>Top Spin Foods Ltd. is a new company with an old soul.  With a combined 100 years of experience in business, management commodities trading and product development, we share an unwavering commitment to delivering quality products at fair prices.</p>

                                    <p>We offer meat, produce, animal proteins, and specialty crops.  Our goal is to build long-term relationships with our customers. We will work with you to help you grow your business and meet your required service levels.</p>

                                    <p>We are skilled in procurement, product specification, commodity purchasing, logistics, documentation and financing. </p>

                                    <p>We have a presence in the USA, Canada, Singapore and the Philippines.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
  </div>
)

export default Mission