import React from 'react'
import bunny from '../assets/images/bunny.jpg'
import vic from '../assets/images/vic.jpg'
import mia from '../assets/images/mia-new.jpg'

const cardStyle = {
    background: 'hsl(0, 0%, 96%)',
    marginBottom: '20px'
}

const Team = () => (
    <div>
    <section className="hero is-fullheight">
        <div className="hero-body">
            <div className="container">
                <div className="columns is-mobile is-centered">
                    <div className="column is-10">
                        <h1 className="title is-size-1 is-uppercase has-text-centered">Our Team</h1>
                        <div className="columns">
                            <div className="column is-one-third">
                                <div className="card card-equal-height" style={cardStyle}>
                                    <div className="card-image">
                                        <figure className="image is-square">
                                            <img src={bunny} alt="Andre Jaranila"/>
                                        </figure>
                                    </div>
                                    <div className="card-content column">
                                        <div className="media">
                                            <div className="media-content">
                                                <p className="title is-4">Andre Jaranilla</p>
                                                <p className="subtitle is-6">Chairman</p>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <p>Andre spent 35 years in Del Monte and retired as the head of plantation operations overseeing 25,000 hectares growing pineapple, papaya, guava, passion fruit and other products. He is currently the president of Lisland Resort and operates several food establishments. He brings extensive agricultural growing background and food industry experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-one-third">
                                <div className="card card-equal-height" style={cardStyle}>
                                    <div className="card-image">
                                        <figure className="image is-square">
                                            <img src={vic} alt="Victor Magno" />
                                        </figure>
                                    </div>
                                    <div className="card-content column">
                                        <div className="media">
                                            <div className="media-content">
                                                <p className="title is-4">Victor Magno</p>
                                                <p className="subtitle is-6">General Manager</p>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <p>Victor spent close to 20 years with OOCL specialising in the reefer trade, and has experience as a food trader, and is a supply chain professional.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-one-third">
                                <div className="card card-equal-height" style={cardStyle}>
                                    <div className="card-image">
                                        <figure className="image is-square">
                                            <img src={mia} alt="Mia Lorenzo"/>
                                        </figure>
                                    </div>
                                    <div className="card-content column">
                                        <div className="media">
                                            <div className="media-content">
                                                <p className="title is-4">Mia Lorenzo</p>
                                                <p className="subtitle is-6">Treasurer</p>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <p>Mia has a master's degree in business and has banking experience with Citibank.  She further honed her finance network and skills while living in the USA, Holland, Monaco and Singapore.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
)

export default Team