import React from 'react'

import beef from '../assets/images/beef.jpg'
import pork from '../assets/images/pork.jpg'
import chicken from '../assets/images/chicken.jpg'
import salmon from '../assets/images/salmon.jpg'
import wheat from '../assets/images/wheat.jpg'
import peas from '../assets/images/peas.jpg'

const Products = () => (
    <div>
    <section className="hero is-fullheight is-light">
        <div className="hero-body">
            <div className="container">
                <h1 className="title is-size-1 is-uppercase has-text-centered">Products</h1>
                <div className="columns is-multiline">
                    <div className="column is-one-third">
                        <div className="card">
                            <div className="card-image">
                                <figure className="image is-5by3">
                                    <img src={chicken} alt="Chicken"/>
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <h1 className="title">Chicken</h1>
                                    </div>
                                </div>
                                <div className="content">
                                    Chicken Leg Quarters
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="card">
                            <div className="card-image">
                                <figure className="image is-5by3">
                                    <img src={beef} alt="Beef"/>
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <h1 className="title">Beef</h1>
                                    </div>
                                </div>
                                <div className="content">
                                    Various Beef Cuts
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="card">
                            <div className="card-image">
                                <figure className="image is-5by3">
                                    <img src={pork} alt="Pork"/>
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <h1 className="title">Pork</h1>
                                    </div>
                                </div>
                                <div className="content">
                                    Various Pork Cuts
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="card">
                            <div className="card-image">
                                <figure className="image is-5by3">
                                    <img src={salmon} alt="Salmon"/>
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <h1 className="title">Salmon</h1>
                                    </div>
                                </div>
                                <div className="content">
                                    Salmon from the Pacific Northwest
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="card">
                            <div className="card-image">
                                <figure className="image is-5by3">
                                    <img src={wheat} alt="Animal Protein"/>
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <h1 className="title">Animal Protein</h1>
                                    </div>
                                </div>
                                <div className="content">
                                    Various Animal Proteins from the Pacific Northwest
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="card">
                            <div className="card-image">
                                <figure className="image is-5by3">
                                    <img src={peas} alt="Peas and Lentils"/>
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <h1 className="title">Peas and Lentils</h1>
                                    </div>
                                </div>
                                <div className="content">
                                    Specialty Crops
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
)

export default Products