import React from 'react'
import contact from '../assets/images/contact-us.jpg'

const bg = {
    backgroundImage: `linear-gradient( rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) ), url(${contact})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
}

const Contact = () => (
  <div>
    <section className="hero is-fullheight" style={bg}>
        <div className="hero-body">
            <div className="container">
                <h1 className="title is-size-1 is-uppercase has-text-centered">Contact</h1>
                <div className="columns">
                    <div className="column is-one-third">
                        <div className="content">
                            <h1 className="title">Inquiries</h1>
                            <p>For any inquiries, please call or email us.</p>
                        </div>
                    </div>
                    <div className="column is-one-third">
                    <div className="content">
                        <h1 className="title">Canada</h1>
                        <p>Rob Lorayes</p>
                        <p><a href="mailto:rob.lorayes@topspinfoods.com">rob.lorayes@topspinfoods.com</a></p>
                        <p>(778) 957-5572</p>

                        <h1 className="title">Singapore</h1>
                        <p>Mia Lorenzo</p>
                        <p><a href="mailto:mia.lorenzo@topspinfoods.com">mia.lorenzo@topspinfoods.com</a></p>
                        <p>(65) 9665-9111</p>
                    </div>
                </div>
                <div className="column is-one-third">
                    <div className="content">
                        <h1 className="title">US</h1>
                        <p>Adrian Jaranilla</p>
                        <p><a href="mailto:adrian.jaranilla@topspinfoods.com">adrian.jaranilla@topspinfoods.com</a></p>
                        <p>(848) 448-2065</p>

                        <h1 className="title">Philippines</h1>
                        <p>Victor Magno</p>
                        <p><a href="mailto:victor.magno@topspinfoods.com">victor.magno@topspinfoods.com</a></p>
                        <p>(63) 917-592-7061</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
  </div>
)

export default Contact