import React from 'react'
import people from '../assets/images/people.jpg'
import workplace from '../assets/images/workplace.jpg'
import office from '../assets/images/office.jpg'

const Services = () => (
    <div>
    <section className="hero is-fullheight is-light">
        <div className="hero-body">
            <div className="container">
                <h1 className="title is-size-1 is-uppercase has-text-centered">Services</h1>
                <div className="columns">
                    <div className="column is-one-third">
                        <div className="card card-equal-height">
                            <div className="card-image">
                                <figure className="image is-5by3">
                                    <img src={people} alt="International Trading"/>
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <h1 className="title">International Trading</h1>
                                    </div>
                                </div>
                                <div className="content">
                                    We trade the major meat proteins including poultry, beef and pork.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="card card-equal-height">
                            <div className="card-image">
                                <figure className="image is-5by3">
                                    <img src={workplace} alt="Domestic Food Service"/>
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <h1 className="title">Domestic Food Service</h1>
                                    </div>
                                </div>
                                <div className="content">
                                    Our food service division offers imported food products to major chains, independent operators and food service distributors.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-one-third">
                        <div className="card card-equal-height">
                            <div className="card-image">
                                <figure className="image is-5by3">
                                    <img src={office} alt="Seafood, Fruits and Vegetables"/>
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <h1 className="title">Seafood, Fruits and Vegetables</h1>
                                    </div>
                                </div>
                                <div className="content">
                                    We source from the Pacific Northwest various salmon species.  We can provide assorted berries, cherries and specialty crops such as peas, beans, lentils, flax seeds and other grains.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
)

export default Services