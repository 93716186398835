import React from 'react'

import tennis from '../assets/images/tennis.jpg'

const bg = {
    backgroundImage: `url(${tennis})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100%',
}

const mb = {
    marginBottom: '0'
}

const AboutUs = () => (
    <div>
        <div className="columns is-gapless" style={mb}>
            <div className="column is-half">
                <div className="hero is-large is-paddingless">
                    <div className="hero-body">
                        <div className="columns is-centered">
                            <div className="column is-half">
                                <div className="content">
                                    <h1 className="title is-size-1 is-uppercase has-text-centered">About Us</h1>

                                    <p>Top Spin Foods Corp. is a new company with an old soul. With a combined 100 years of experience in business, management, commodities trading and product development, we share an unwavering commitment to delivering quality products at fair prices.</p>

                                    <p>In tennis a top spin shot means that the ball will bounce high after landing.</p>

                                    <p>Our hope is that the business of our customers also get an upward lift or upward trajectory after working with our team.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-half is-paddingless">
                <div className="hero is-large" style={bg}></div>
            </div>
        </div>
        <div className="hero is-warning">
            <div className="hero-body">
                <div className="container">
                    <nav className="level">
                        <div className="level-item has-text-centered">
                            <div>
                            <p className="title">2018</p>
                            <p className="heading is-size-6">Year Established</p>
                            </div>
                        </div>
                        <div className="level-item has-text-centered">
                            <div>
                            <p className="title">100</p>
                            <p className="heading is-size-6">Years of Combined Experience</p>
                            </div>
                        </div>
                        <div className="level-item has-text-centered">
                            <div>
                            <p className="title">4</p>
                            <p className="heading is-size-6">Countries We Have a Presence In</p>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
  </div>
)

export default AboutUs