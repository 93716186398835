import React from 'react'

import Layout from '../components/layout'
import Intro from '../components/intro'
import Products from '../components/Products'
import About from '../components/About'
import Team from '../components/Team'
import Mission from '../components/Mission'
import Services from '../components/Services'
import Contact from '../components/Contact'

const IndexPage = () => (
  <Layout>
    <div id="intro">
      <Intro />
    </div>
    <div id="products">
      <Products />
    </div>
    <div id="about">
      <About />
    </div>
    <div id="team">
      <Team />
    </div>
    <div id="mission">
      <Mission />
    </div>
    <div id="services">
      <Services />
    </div>
    <div id="contact">
      <Contact />
    </div>
  </Layout>
)

export default IndexPage
